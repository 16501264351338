<template>
  <div>
    <div class="px-4 pt-2">
      <h3>Resumo da Estocagem</h3>
      <p>Verifique os resíduos e a peso selecionado antes de encaminhar</p>
    </div>
    <v-divider></v-divider>
    <v-data-table
      v-if="selectedResidueSubtypes.length"
      :headers="headers"
      :items="selectedResidueSubtypes"
      :items-per-page="selectedResidueSubtypes.length"
      hide-default-footer
    ></v-data-table>
    <residue-type-not-found
      v-else
      class="mt-10"
      :title="['Selecione um resíduo que deve ser encaminhado para o estoque']"
    />
  </div>
</template>

<script>
  import ResidueTypeNotFound from "@/modules/stock/demanufacturing/views/components/ResidueTypeNotFound";

  export default {
    name: "UpdateSummary",

    props: {
      selectedResidueSubtypes: {
        type: Array,
        required: true
      }
    },

    components: {
      ResidueTypeNotFound
    },

    data: () => ({
      headers: [
        { text: "Subtipo de Resíduo", value: "description" },
        {
          text: "Peso Desmanufaturado (kg)",
          value: "demanufacturedWeight"
        }
      ]
    })
  };
</script>

<style lang="scss" scoped></style>
