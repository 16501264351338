<template>
  <v-container>
    <title-header :title="pageTitle" />

    <v-row>
      <v-col cols="12" md="7">
        <v-card>
          <detail-residue-list
            v-if="detail.residueTypes"
            :key="`detail-list-${updateKey}`"
            :residueTypeList="detail.residueTypes"
            :select="true"
            v-model="selectedResidueSubtypes"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="5">
        <v-card>
          <update-summary :selectedResidueSubtypes="selectedResidueSubtypes" />
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="12" sm="5" md="4" lg="3">
        <v-btn
          color="secondary-color-1"
          class="next-step-button white--text"
          :disabled="!selectedResidueSubtypes.length"
          block
          @click="prepareToSendResidueSubtypeToStock"
        >
          Encaminhar para o Estoque
        </v-btn>
      </v-col>
      <v-col cols="12" sm="5" md="4" lg="3">
        <v-btn
          color="secondary-color-1"
          class="next-step-button"
          block
          outlined
          :disabled="!detail.canConcludeDemanufacturing"
          @click="prepareToConcludeDemanufacturing"
        >
          Concluir Desmanufatura
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import UpdateSummary from "@/modules/stock/demanufacturing/views/components/UpdateSummary";
  import DetailResidueList from "@/modules/stock/demanufacturing/views/components/DetailResidueList";

  import { mapActions } from "vuex";

  export default {
    name: "UpdateDemanufacturing",

    components: {
      TitleHeader,
      UpdateSummary,
      DetailResidueList
    },

    data: () => ({
      detail: {},
      updateKey: 1,

      selectedResidueSubtypes: []
    }),

    mounted() {
      this.batchId = this.$route.params.id;
      this.fetchData();
    },

    computed: {
      pageTitle() {
        return `Registro Lote ${this.detail?.batchNumber}`;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/demanufacturing", [
        "getDemanufacturingById",
        "sendResidueStock",
        "concludeDemanufacturing"
      ]),

      clearList() {
        this.updateKey++;
        this.selectedResidueSubtypes = [];
      },

      async fetchData(clear) {
        try {
          const { data } = await this.getDemanufacturingById(this.batchId);
          this.detail = data;

          if (clear) {
            this.clearList();
          }
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      prepareToSendResidueSubtypeToStock() {
        this.$root.$confirm
          .open(
            "Deseja encaminhar os itens selecionados para o estoque?",
            "Os itens selecionados irão ser encaminhados para a sua destinação final. Antes será necessário especificar a pesagem dos subtipos de resíduos."
          )
          .then(accepted => {
            if (accepted) {
              this.handleSendResidueSubtypeToStock();
            }
          });
      },

      prepareToConcludeDemanufacturing() {
        this.$root.$confirm
          .open(
            `Deseja concluir a desmanufatura do lote ${this.detail?.batchNumber}?`,
            "Ao concluir o registro não é possível acrescentar nenhum tipo de resíduo. Antes será necessário especificar a pesagem dos subtipos de resíduos.",
            { width: 500 }
          )
          .then(accepted => {
            if (accepted) {
              this.handleConcludeDemanufacturing();
            }
          });
      },

      async handleSendResidueSubtypeToStock() {
        try {
          const payload = this.mountSendResiduePayload();
          const response = await this.sendResidueStock(payload);

          this.toggleSnackbar({
            text: response?.data?.message ?? "Resíduo enviado com sucesso",
            type: "success"
          });

          this.fetchData(true);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      mountSendResiduePayload() {
        return {
          id: this.batchId,
          body: {
            residueIds: this.selectedResidueSubtypes.map(
              residueSubtype => residueSubtype.batchItemDemanufacturedId
            )
          }
        };
      },

      async handleConcludeDemanufacturing() {
        try {
          const response = await this.concludeDemanufacturing(this.batchId);

          this.toggleSnackbar({
            text:
              response?.data?.message ??
              "Desmanufatura do lote concluída com sucesso",
            type: "success"
          });

          this.$router.push({ name: "demanufacturing" });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
