<template>
  <v-data-table
    :headers="headers"
    :items="residueSubtypeList"
    :show-select="select"
    :value="value"
    :item-class="getItemClass"
    item-key="residueSubtypeId"
    selectable-key="canSendStock"
    @input="$emit('input', $event)"
    hide-default-footer
  ></v-data-table>
</template>

<script>
  export default {
    name: "TableResidueSubtype",

    props: {
      residueType: {
        type: Object,
        required: true
      },
      isMobile: {
        type: Boolean,
        required: true
      },
      headers: {
        type: Array,
        required: true
      },
      select: {
        type: Boolean,
        required: false,
        default: () => false
      },
      value: {
        type: null,
        required: false,
        default: () => []
      }
    },

    data: () => ({
      residueSubtypeList: []
    }),

    mounted() {
      this.initSubtypeList();
    },

    methods: {
      initSubtypeList() {
        this.residueSubtypeList = [
          ...this.residueType.residueSubtypes,
          {
            residueSubtypeId: -1,
            canSendStock: false,
            description: "Total",
            demanufacturedWeight: this.residueType.demanufacturedWeight,
            destinedWeight: this.residueType.destinedWeight,
            screeningTotalWeight: this.residueType.screeningTotalWeight
          }
        ];
      },

      getItemClass(item) {
        return item.residueSubtypeId === -1 ? "contrast" : "";
      }
    }
  };
</script>

<style lang="scss">
  .contrast {
    font-weight: 600;

    td:first-of-type > div {
      display: none;
    }
  }
</style>
