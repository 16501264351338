<template>
  <div class="table-page">
    <v-expansion-panels
      accordion
      flat
      class="pa-2"
      v-if="residueTypeList.length"
    >
      <expansion-panel
        v-for="(residueType, i) in residueTypeList"
        :key="i"
        :overflow="false"
      >
        <template slot="title">
          {{ residueType.description }}
        </template>
        <template slot="content">
          <table-residue-subtype
            :key="`table-${updateKey}`"
            :residueType="residueType"
            :isMobile="$isMobile"
            :headers="headers"
            :select="select"
            :value="value"
            @input="$emit('input', $event)"
          />
        </template>
      </expansion-panel>
    </v-expansion-panels>

    <div v-else class="inherit-height d-flex flex-column justify-center">
      <residue-type-not-found :title="dataNotFound" />
    </div>
  </div>
</template>

<script>
  import TableResidueSubtype from "@/modules/stock/demanufacturing/views/components/TableResidueSubtype";
  import ResidueTypeNotFound from "@/modules/stock/demanufacturing/views/components/ResidueTypeNotFound";
  import ExpansionPanel from "@/modules/core/views/components/ExpansionPanel";

  export default {
    name: "DetailResidueList",

    props: {
      residueTypeList: {
        type: Array,
        required: true
      },
      select: {
        type: Boolean,
        required: false,
        default: () => false
      },
      value: {
        type: null,
        required: false,
        default: () => []
      },
      updateKey: {
        type: Number,
        required: false,
        default: () => 1
      }
    },

    components: {
      ResidueTypeNotFound,
      TableResidueSubtype,
      ExpansionPanel
    },

    data: () => ({
      showList: false,
      mode: "LIST",
      expanded: [],
      headers: [
        { text: "Subtipo de Resíduo", value: "description" },
        {
          text: "Desmanufaturado (kg)",
          value: "demanufacturedWeight"
        },
        { text: "Encaminhado (kg)", value: "destinedWeight" },
        {
          text: "Peso Total (kg)",
          value: "screeningTotalWeight"
        }
      ]
    }),

    computed: {
      dataNotFound() {
        return [
          "Você ainda não possui nenhum registro de resíduo cadastrado",
          "Cadastre o primeiro"
        ];
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .font-15 {
    font-size: 15px;
  }

  ::v-deep .v-data-table__expand-icon {
    color: $black-3 !important;
  }

  ::v-deep .v-data-table__expanded__row {
    background-color: $white-1 !important;
  }
</style>
